import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Seo from "../components/seo"
import * as projectStyles from "../components/project.module.css"
import { Link } from "gatsby"
import Carousel from "react-bootstrap/Carousel"


const AWWerbePage = () => (
  <div>
    <Seo title="Druckmedien für Altergott Webdesign GbR - Altergott Studios" lang="de" description="Ein Falzflyer wurde für die Webagentur Altergott Webdesign gestaltet, der das Angebot, die Referenzen, sowie die Vorteile und Kontaktdaten der Webagentur potentiellen Kunden vermittelt." />

    <Layout>
      <Header />
      <div>
        <div className={projectStyles.carouselWrapper}>
          <Carousel className={projectStyles.carousel}>
            <Carousel.Item>
              <div className="slide seven"></div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="slide eight"></div>
            </Carousel.Item>
          </Carousel>
        </div>

        <div className={projectStyles.projectGrid}>
          <div>
            <h3 className={projectStyles.uppercase}>Kunde</h3>

            <p>Altergott Webdesign GbR (Mitgründer)</p>
            <Link
              to="https://www.altergottwebdesign.com"
              className={projectStyles.projectLink}
            >
              altergottwebdesign.com
            </Link>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Projektbeschreibung</h3>

            <p>Leistungen: Erstellung eines Falzflyers</p>
            <p>
              Ein Falzflyer wurde für die Webagentur Altergott Webdesign
              gestaltet, der das Angebot, die Referenzen, sowie die Vorteile und
              Kontaktdaten der Webagentur potentiellen Kunden vermittelt.
            </p>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Auswirkung</h3>

            <p>
              Durch die Verteilung der Flyer hat die Webagentur mehr
              Aufmerksamkeit bekommen und Interessierten wichtige Fragen
              beantwortet.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  </div>
)

export default AWWerbePage
